<section
	class="relative md:h-auto bg-no-repeat bg-cover bg-center w-screen section-header"
>
	<img
		[src]="sectionCoverPicture"
		alt=""
		class="w-full min-h-[45vh]  object-cover "
	/>

	<div
		class="absolute inset-0 z-10 w-4/5 mx-auto grid items-center section-content"
	>
		<h2
			class="header-title text-fontColor uppercase font-black lg:text-6xl md:text-4xl text-2xl"
			[ngClass]="setBackgroundToAcademy ? 'header-background-academy' : 'no-background'">
		
			{{ sectionHeadTitle }}

			@if (sectionSubHeadTitle && sectionSubHeadTitle.length > 0) {
			<p
				class="text-fontColor lg:text-2xl text-xl mt-4 head1 d-inline-block"
				[ngClass]="setBackgroundToAcademy ? 'header-background-academy' : 'no-background'">
				{{ sectionSubHeadTitle }}
			</p>
			}
		</h2>
	</div>
</section>
