import { CommonModule } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "e3lan-card",
	templateUrl: "./e3lan-card.component.html",
	styleUrl: "./e3lan-card.component.scss",
	standalone: true,
	imports: [CommonModule],
})
export class E3lanCardComponent implements OnInit {
	// Inject
	public _router: Router = inject(Router);

	@Input() desktop: string; // Desktop image
	@Input() tablet: string; // Tablet image
	@Input() mobile: string; // Mobile image
	@Input() image: string;
	@Input() adLayout: string;
	@Input() customHeight: string;
	@Input() navigateTo: string;
	@Input() customWidth: string = "80%";
	ngOnInit(): void {
		this.setResponsiveImage();
	}
	@HostListener("window:resize", [])
	onResize() {
		this.setResponsiveImage();
	}
	private setResponsiveImage(): void {
		if (window.innerWidth < 768) {
			this.image = this.mobile;
		} else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
			this.image = this.tablet;
		} else if (window.innerWidth >= 1024) {
			this.image = this.desktop;
		}
	}
	getStyles(layout: string): any {
		return layout === "vertical"
			? {
					"background-image": `url('${this.image}')`,
					height: `${this.customHeight}`,
			  }
			: {
					"background-image": `url('${this.image}')`,
					width: this.customWidth,
			  };
	}

	public onNavigateTo(route: string) {
		console.log("route",route);
		
		this._router.navigateByUrl(route)
	}
}
