<span (click)="onNavigateTo(navigateTo)">
	<ng-container [ngSwitch]="adLayout">
		<div
			*ngSwitchCase="'vertical'"
			class="e3lan-section vertical w-full"
			[ngStyle]="getStyles('vertical')"
		></div>
		<div
			*ngSwitchDefault
			class="e3lan-section w-full horizontal"
			[ngStyle]="getStyles('horizontal')"
		></div>
	</ng-container>
	
</span>